<template>
  <div>
    <div id="opties-row">
      <div class="row">
        <div class="col-md-12 d-block d-md-flex align-items-center justify-content-between">
          <div id="options-left">
            <button class="btn btn-light"
            @click="toggleFilterModal"
              id="filter-button"
              ref = "filterButton"
            >
              <font-awesome-icon :icon="['far', 'filter']" /> Filters
                <small><kbd>F</kbd></small>
            </button>
            <select class="form-select d-inline-flex"
              v-model="sortColumn.label"
              id="sort-column"
              ref = "sortColumn">
              <option v-for="column in columns" :key="column.field" :value="column.label">
                {{ column.label }}
              </option>
            </select>
            <button
              id="sort-direction"
              class="btn btn-light"
              @click="toggleSortDirection"
            >
              <font-awesome-icon v-if="sortColumn.direction == 'asc'" :icon="['far', 'sort-up']" />
              <font-awesome-icon v-else-if="sortColumn.direction == 'desc'" :icon="['far', 'sort-down']" />
              <font-awesome-icon v-else :icon="['far', 'sort']" />
            </button>
          </div>
          <div id="options-center" class="text-center">
            <span>
              {{ filteredData.length }} van {{ aanmeldingen.length}} aanmeldingen
            </span>
          </div>
          <div id="options-right">
            <button class="btn btn-light" data-bs-toggle="collapse" data-bs-target="#column-selector" aria-expanded="false" aria-controls="column-selector">
              <font-awesome-icon :icon="['far', 'table-columns']" /> Kolommen
            </button>
            <div class="settings-group collapse column-selector" id="column-selector">
              <draggable v-model="columns" :options="{ handle: '.handle' }" :item-key="item => item.field">
                <template #item="{ element }">
                  <div class="column-selector__chip">
                    <label class="column-selector__label">
                      <input type="checkbox" class='checkbox-custom' v-model="element.visible" />
                      <span class="checkbox-custom__indicator"></span>
                      {{ element.label }}
                    </label>
                    <font-awesome-icon icon="fa-regular fa-grip-dots-vertical" />
                  </div>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>

        <div v-if="showFilterModal"
          class="modal d-block d-md-flex"
        >
          <div ref="filterModalCard" class="col-md-8 modal-card">
            <!-- <input type="search"
              class="form-control"
              v-model.lazy="searchQuery"
              ref = "searchInput"
              placeholder="Zoek op alle velden"
            >
            <hr> -->
            <div class="row">
              <div class="col-12">
                <h3>Filters</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <!-- foreach column, add an input  -->
                <div
                  v-for="column in columns"
                  :key="column.field"
                >
                  <div class="mb-3">
                    <input
                      v-if="(!column.type || column.type == 'text') && column.allowFilter"
                      type="search"
                      v-model.lazy="column.filter"
                      class="form-control"
                      :placeholder="column.label"
                    >
                    <div v-if="column.type === 'number' && column.allowFilter">
                      <div class="row">
                        <div class="col-12">
                          <label>{{ column.label }} (min/max)</label>
                        </div>
                        <div class="col-md-6">
                          <input
                            type="number"
                            v-model.lazy="column.filter.min"
                            class="form-control"
                            :placeholder="column.label + ' min'"
                          >
                        </div>
                        <div class="col-md-6">
                          <input
                            type="number"
                            v-model.lazy="column.filter.max"
                            class="form-control"
                            :placeholder="column.label + ' max'"
                          >
                        </div>
                      </div>
                    </div>
                    <table v-if="column.type === 'voorkeuren' && column.allowFilter"
                      class="table table-sm"
                      >
                      <thead>
                        <tr>
                          <th></th>
                          <th
                            v-for = "dag in dagen"
                            :key = "dag.id"
                          >{{ dag.description }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for = "deel in dagdelen"
                          :key = "deel.id"
                        >
                          <td>{{ deel.periode }}</td>
                          <td
                            v-for = "dag in dagen"
                            :key = "dag.id"
                          >
                            <input type="checkbox"
                              class="form-check-input"
                              :id="dag.id + '-' + deel.id"
                              :value="{
                                dagId: dag.id,
                                dagdeelId: deel.id,

                                }"
                              v-model="column.filterVoorkeuren"
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6"
                    v-for="column in columnsWithSelects"
                    :key="column.field"
                  >
                    <div class="row">
                      <div class="col-12">
                        <label>{{ column.label }}</label>
                      </div>
                      <div class="col-12">
                        <div class="form-check" v-for="niveau in  this.$store.state.vergaarbak.niveaus[column.populate]" :key="niveau">
                          <input type="checkbox"
                            class="form-check-input"
                            :id="niveau"
                            :value="niveau"
                            v-model="column.filter"
                          >
                          <label class="form-check-label" :for="niveau">{{ niveau.klasTypeId }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-6">
                <div class="form-check">
                  <input type="checkbox"
                    v-model="groupFamilies"
                    class="form-check-input"
                    id="groupFamilies"
                  >
                  <label class="form-check-label" for="groupFamilies">Groepeer families</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-check">
                  <input type="checkbox"
                    v-model="toonUnsetNiveaus"
                    class="form-check-input"
                    id="toonNiv"
                  >
                  <label class="form-check-label" for="toonNiv">Toon onbekende niveaus</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showSortModal"
          class="modal d-block d-md-flex"
        >
          <div ref="sortModalCard" class="modal-card">

          </div>
        </div>
        <div v-if='aanmeldingen'>
        <vue-good-table
            class='mb-5'
            :columns="this.columns.filter((column) => column.visible)"
            :rows="filteredData"
            :pagination-options="pagination"
            :sort-options="{enabled: false}"
            v-on:row-click="onAanmeldingClick"
            >
            <template #table-row="props">
                <span v-if="props.column.field == 'customStatus'">
                     <status-badge :status='props.row.aanmeldingAanmeldingStatusTypes[0]' :klant='false' />
                </span>
                <span v-else-if="props.column.field == 'eersteVoorkeur'">
                  <div v-if="props.row.preftime1 != null && props.row.preftime1.hasOwnProperty('dag')">
                    <span v-if="props.row.preftime1.id === 22">
                      -
                    </span>
                    <span v-else>
                      {{ props.row.preftime1.dag.description.charAt(0).toUpperCase()}}{{ props.row.preftime1.dag.description.slice(1) }}{{ props.row.preftime1.dagdeel.periode }}
                    </span>
                  </div>
                </span>
                <span v-else-if="props.column.field == 'tweedeVoorkeur'">
                  <div v-if="props.row.preftime2 != null && props.row.preftime2.hasOwnProperty('dag')">
                    <span v-if="props.row.preftime2.id === 22">
                      -
                    </span>
                    <span v-else>
                      {{ props.row.preftime2.dag.description.charAt(0).toUpperCase()}}{{ props.row.preftime2.dag.description.slice(1) }}{{ props.row.preftime2.dagdeel.periode }}
                    </span>
                  </div>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
  </div>
</template>
<script>

// <example> object aanmelding
// {
//     "id": 215,
//     "groupId": 3233,
//     "skisnowboardId": 1,
//     "cursusId": 3,
//     "materiaalId": 3,
//     "skikaartId": 3,
//     "preftime1Id": 8,
//     "preftime2Id": 2,
//     "signupdate": "2023-07-12T15:21:00",
//     "remark": "",
//     "selected": null,
//     "userId": null,
//     "actief": true,
//     "customerId": 7330,
//     "pistesSelected": "-",
//     "techniek1sSelected": "-",
//     "cursus": {
//         "id": 3,
//         "titel": "Privéles winter",
//         "omschrijving": "Voor één persoon of meerdere personen van hetzelfde niveau. Voor de allerkleinsten is ook 25 minuten mogelijk. Privéles is inclusief materiaalhuur.",
//         "periode": "In overleg",
//         "zichtbaarVan": "2021-08-01T00:00:00",
//         "zichtbaarTot": "2022-04-01T00:00:00",
//         "isPriveles": true,
//         "cursusDagDagdeels": []
//     },
//     "customer": {
//         "groupId": 3233,
//         "customerId": 7330,
//         "firstname": "Inge",
//         "sex": 2,
//         "birthdate": "1963-09-04T00:00:00",
//         "skiExperience": 0,
//         "snowboardExperience": 0,
//         "actief": true,
//         "modified": "2019-07-25T14:03:24.48",
//         "property": "houdt niet van sleeplift",
//         "currentLevel": null,
//         "remark": null,
//         "vermoedelijkNiveauSki": "B1",
//         "vermoedelijkNiveauSnowboard": null
//     },
//     "group": {
//         "groupId": 3233,
//         "prelastname": "van",
//         "lastname": "Schaik",
//         "adress": "Nedereindseweg 507 b",
//         "zipcode": "3546 PM",
//         "city": "Utrecht",
//         "phone1": "0306877028",
//         "phone2": "0306877030",
//         "phone3": "0655827486",
//         "email": "info@founditsoftware.nl",
//         "started": "2005-09-17T00:00:00",
//         "lid2000": false,
//         "lid2001": false,
//         "lid2002": false,
//         "lid2003": false,
//         "lid2004": false,
//         "lid2005": true,
//         "lid2006": false,
//         "lid2007": false,
//         "lid2008": false,
//         "lid2009": false,
//         "lid2010": false,
//         "lid2011": false,
//         "lid2012": false,
//         "lid2013": false,
//         "lid2014": false,
//         "lid2015": false,
//         "lid2016": false,
//         "lid2017": false,
//         "lid2018": false,
//         "lid2019": false,
//         "lid2020": false,
//         "actief": true,
//         "modified": "2013-10-05T17:16:21",
//         "nieuwsbrief": false,
//         "email2": "",
//         "nieuwsbrief2": false,
//         "lid2021": false,
//         "lid2022": false,
//         "lid2023": false,
//         "lid2024": false,
//         "lid2025": false,
//         "lid2026": false,
//         "lid2027": false,
//         "lid2028": false,
//         "lid2029": false,
//         "lid2030": false,
//         "lid2031": false,
//         "lid2032": false,
//         "lid2033": false,
//         "lid2034": false,
//         "lid2035": false,
//         "lid2036": false,
//         "lid2037": false,
//         "lid2038": false,
//         "lid2039": false,
//         "lid2040": false,
//         "clients": [
//             {
//                 "groupId": 3233,
//                 "customerId": 7330,
//                 "firstname": "Inge",
//                 "sex": 2,
//                 "birthdate": "1963-09-04T00:00:00",
//                 "skiExperience": 0,
//                 "snowboardExperience": 0,
//                 "actief": true,
//                 "modified": "2019-07-25T14:03:24.48",
//                 "property": "houdt niet van sleeplift",
//                 "currentLevel": null,
//                 "remark": null,
//                 "vermoedelijkNiveauSki": "B1",
//                 "vermoedelijkNiveauSnowboard": null
//             }
//         ],
//         "orders": []
//     },
//     "materiaal": {
//         "id": 3,
//         "titel": "Alleen ski's",
//         "skiSnowboardId": 1,
//         "actief": true,
//         "skiSnowboard": {
//             "showGroup2Id": 1,
//             "omschrijving": "Ski"
//         }
//     },
//     "preftime1": {
//         "dagId": 3,
//         "dagdeelId": 2,
//         "id": 8,
//         "dag": {
//             "prechoiceId": 3,
//             "description": "dinsdag"
//         },
//         "dagdeel": {
//             "id": 2,
//             "periode": "middag"
//         }
//     },
//     "preftime2": {
//         "dagId": 1,
//         "dagdeelId": 2,
//         "id": 2,
//         "dag": {
//             "prechoiceId": 1,
//             "description": "zondag"
//         },
//         "dagdeel": {
//             "id": 2,
//             "periode": "middag"
//         }
//     },
//     "skikaart": {
//         "id": 3,
//         "skikaart1": "9 uren strippenkaart",
//         "toelichting": null,
//         "actief": true,
//         "persoonsgebonden": "Niet persoonsgebonden"
//     },
//     "skisnowboard": {
//         "showGroup2Id": 1,
//         "omschrijving": "Ski"
//     },
//     "aanmeldingHistory": {
//         "aanmeldingId": 215,
//         "eerder": false,
//         "afgelopenSeizoen": false,
//         "wekenErvaring": 0,
//         "laatsteJaar": null,
//         "modified": "2023-07-12T17:21:00"
//     },
//     "aanmeldingAanmeldingStatusTypes": [
//         {
//             "aanmeldingId": 215,
//             "aanmeldingStatusTypeId": 2,
//             "time": "2023-07-20T15:12:00",
//             "aanmeldingStatusType": {
//                 "id": 2,
//                 "aanmeldingStatusType1": "In behandeling",
//                 "aanmeldingStatusTypeKlant": "In behandeling",
//                 "isDefault": null,
//                 "aanmeldingCategorie": 2,
//                 "aanmeldingCategorieNavigation": null
//             }
//         },
//         {
//             "aanmeldingId": 215,
//             "aanmeldingStatusTypeId": 4,
//             "time": "2023-07-20T15:12:00",
//             "aanmeldingStatusType": {
//                 "id": 4,
//                 "aanmeldingStatusType1": "Wacht op antwoord klant",
//                 "aanmeldingStatusTypeKlant": "Wacht op antwoord van klant",
//                 "isDefault": null,
//                 "aanmeldingCategorie": 2,
//                 "aanmeldingCategorieNavigation": null
//             }
//         },
//         {
//             "aanmeldingId": 215,
//             "aanmeldingStatusTypeId": 4,
//             "time": "2023-07-20T15:11:00",
//             "aanmeldingStatusType": {
//                 "id": 4,
//                 "aanmeldingStatusType1": "Wacht op antwoord klant",
//                 "aanmeldingStatusTypeKlant": "Wacht op antwoord van klant",
//                 "isDefault": null,
//                 "aanmeldingCategorie": 2,
//                 "aanmeldingCategorieNavigation": null
//             }
//         },
//         {
//             "aanmeldingId": 215,
//             "aanmeldingStatusTypeId": 5,
//             "time": "2023-07-20T15:11:00",
//             "aanmeldingStatusType": {
//                 "id": 5,
//                 "aanmeldingStatusType1": "Ingepland",
//                 "aanmeldingStatusTypeKlant": "Ingepland, orderbevestiging wordt z.s.m. gemaild",
//                 "isDefault": null,
//                 "aanmeldingCategorie": 3,
//                 "aanmeldingCategorieNavigation": null
//             }
//         },
//         {
//             "aanmeldingId": 215,
//             "aanmeldingStatusTypeId": 3,
//             "time": "2023-07-20T15:09:00",
//             "aanmeldingStatusType": {
//                 "id": 3,
//                 "aanmeldingStatusType1": "Extra actie vereist",
//                 "aanmeldingStatusTypeKlant": "In behandeling",
//                 "isDefault": null,
//                 "aanmeldingCategorie": 2,
//                 "aanmeldingCategorieNavigation": null
//             }
//         },
//         {
//             "aanmeldingId": 215,
//             "aanmeldingStatusTypeId": 4,
//             "time": "2023-07-20T11:00:00",
//             "aanmeldingStatusType": {
//                 "id": 4,
//                 "aanmeldingStatusType1": "Wacht op antwoord klant",
//                 "aanmeldingStatusTypeKlant": "Wacht op antwoord van klant",
//                 "isDefault": null,
//                 "aanmeldingCategorie": 2,
//                 "aanmeldingCategorieNavigation": null
//             }
//         },
//         {
//             "aanmeldingId": 215,
//             "aanmeldingStatusTypeId": 3,
//             "time": "2023-07-20T10:59:00",
//             "aanmeldingStatusType": {
//                 "id": 3,
//                 "aanmeldingStatusType1": "Extra actie vereist",
//                 "aanmeldingStatusTypeKlant": "In behandeling",
//                 "isDefault": null,
//                 "aanmeldingCategorie": 2,
//                 "aanmeldingCategorieNavigation": null
//             }
//         },
//         {
//             "aanmeldingId": 215,
//             "aanmeldingStatusTypeId": 1,
//             "time": "2023-07-12T15:21:00",
//             "aanmeldingStatusType": {
//                 "id": 1,
//                 "aanmeldingStatusType1": "Aanmelding ontvangen",
//                 "aanmeldingStatusTypeKlant": "Aanmelding is ontvangen",
//                 "isDefault": true,
//                 "aanmeldingCategorie": 2,
//                 "aanmeldingCategorieNavigation": null
//             }
//         }
//     ],
//     "aanmeldingOpmerkings": [],
//     "aanmeldingPistes": [],
//     "aanmeldingTechniek1s": []
// }
// </example>

import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import statusBadge from '@/components/aanmeldingen/StatusBadge.vue'
import draggable from 'vuedraggable'
import Fuse from 'fuse.js'

const packageJson = require('../../../package.json')
const STORAGE_KEY = `datatable_columns_${packageJson.version}`

export default {
  name: 'datatable',
  props: {
    aanmeldingen: {
      type: Array,
      required: true
    }
  },
  components: {
    VueGoodTable,
    statusBadge,
    draggable
  },
  computed: {
    groupFamilies: {
      get () {
        return this.$store.state.vergaarbak.groupFamilies
      },
      set (value) {
        this.$store.commit('vergaarbak/setGroupFamilies', value)
      }
    },
    toonUnsetNiveaus: {
      get () {
        return this.$store.state.vergaarbak.toonUnsetNiveaus
      },
      set (value) {
        this.$store.commit('vergaarbak/setToonUnsetNiveaus', value)
      }
    },
    sortColumn: {
      get () {
        return this.$store.state.vergaarbak.sortColumn
      },
      set (value) {
        this.$store.commit('vergaarbak/setSortColumn', value)
      }
    },
    storedColumns () {
      const storedData = localStorage.getItem(this.storageKey)
      return storedData ? JSON.parse(storedData) : null
    },
    filteredData () {
      let aanmeldingen = this.aanmeldingen

      const reset = () => {
        this.and = []
        this.numbers = []
        this.selects = []
        this.voorkeuren = []
      }
      reset()

      this.columns.forEach((column) => {
        if (column.filter && column.filter !== '' && (!column.type || column.type === 'text')) {
          this.and = this.and || []
          this.and.push({ [column.field]: column.filter })
        }
        if (column.filter && column.filter !== '' && column.type === 'number') {
          this.numbers = this.numbers || []
          this.numbers.push({ [column.filterField]: column.filter })
        }
        if (column.filter && column.filter.length > 0 && column.type === 'select') {
          this.selects = this.selects || []
          this.selects.push({ [column.filterField]: column.filter })
        }
        if (column.type === 'voorkeuren' && column.filterVoorkeuren && column.filterVoorkeuren.length > 0) {
          this.voorkeuren = this.voorkeuren || []
          this.voorkeuren.push({ [column.filterField]: column.filterVoorkeuren })
        }
      })
      if (this.numbers.length > 0) {
        aanmeldingen = this.filterDataByNumbers(aanmeldingen)
      }
      if (this.and.length > 0) {
        aanmeldingen = this.filterDataByText(aanmeldingen, this.and)
      }
      if (this.selects.length > 0) {
        aanmeldingen = this.filterDataBySelect(aanmeldingen)
      }
      if (this.voorkeuren.length > 0) {
        aanmeldingen = this.filterDataByVoorkeuren(aanmeldingen)
      }

      aanmeldingen = this.sortAanmeldingen(aanmeldingen)

      if (!this.groupFamilies) {
        return aanmeldingen
      }
      return this.groupClientsFromSameGroup(aanmeldingen)
    },
    columnsWithSelects () {
      return this.columns.filter((column) => column.type === 'select')
    }
  },
  data () {
    return {
      storageKey: `${STORAGE_KEY}`,
      searchQuery: '',
      filterQuery: [],
      showFilterModal: false,
      showSortModal: false,
      sortDateByGroup: true,
      and: [],
      numbers: [],
      selects: [],
      voorkeuren: [],
      dagen: [
        { id: 1, description: 'zo' },
        { id: 2, description: 'm' },
        { id: 3, description: 'di' },
        { id: 4, description: 'w' },
        { id: 5, description: 'do' },
        { id: 6, description: 'v' },
        { id: 7, description: 'za' }
      ],
      dagdelen: [
        { id: 1, periode: 'ochtend' },
        { id: 2, periode: 'middag' },
        { id: 3, periode: 'avond' }
      ],
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 100,
        position: 'bottom',
        perPageDropdown: [5, 10, 25, 50, 100, 200, 500],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        nextLabel: 'volgende',
        prevLabel: 'vorige',
        rowsPerPageLabel: 'Toon',
        ofLabel: 'of',
        pageLabel: 'pagina', // for 'pages' mode
        allLabel: 'Alle',
        infoFn: (params) => `Pagina ${params.currentPage}`
      },
      columns: [
        {
          label: 'Voornaam',
          field: 'voornaam',
          visible: true,
          filter: '',
          filterField: 'customer.firstname',
          allowFilter: true,
          sortDir: 0, // 0 = none, 1 = asc, 2 = desc
          sortOrder: 0 // index of order for sorting columns
        },
        {
          label: 'Achternaam',
          field: 'achternaam',
          visible: true,
          filter: '',
          filterField: 'achternaam',
          allowFilter: true
        },
        {
          label: 'Leeftijd',
          field: 'leeftijd',
          type: 'number',
          filter: {
            min: 0,
            max: 100
          },
          filterDefault: {
            min: 0,
            max: 100
          },
          filterField: 'leeftijd',
          allowFilter: true
        },
        {
          label: 'Mnd',
          field: 'maanden',
          type: 'number',
          filter: false,
          filterField: 'maanden',
          allowFilter: false
        },
        {
          label: 'Cursus',
          field: 'cursus_titel',
          visible: true,
          filter: '',
          filterField: 'cursus.titel',
          allowFilter: true
        },
        {
          label: 'Datum',
          field: 'signupdate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd H:m:s',
          dateOutputFormat: 'd-M-y H:mm',
          visible: true,
          filter: '',
          filterField: 'signupdate',
          allowFilter: false
        },
        {
          label: 'Status',
          field: 'customStatus',
          filterField: 'aanmeldingAanmeldingStatusTypes.aanmeldingStatusType.aanmeldingStatusType1',
          allowFilter: false
        },
        {
          label: 'Eerste voorkeur',
          field: 'eersteVoorkeur',
          filterField: 'preftime1.dag.description',
          allowFilter: true,
          type: 'voorkeuren',
          filterVoorkeuren: []
        },
        {
          label: 'Tweede voorkeur',
          field: 'tweedeVoorkeur',
          filterField: 'preftime2.dag.description',
          allowFilter: false, // added to 1 because couple is only of type
          type: 'voorkeuren',
          filterVoorkeuren: []
        },
        {
          label: 'Skiniveau',
          field: 'skiniveau',
          columnId: 'klasTypeId',
          populate: 'ski',
          type: 'select',
          filter: [],
          allowFilter: true
        },
        {
          label: 'Snowboardniveau',
          field: 'snowboardniveau',
          columnId: 'klasTypeId',
          populate: 'snowboard',
          type: 'select',
          filter: [],
          allowFilter: true
        }
      ]
    }
  },
  methods: {
    getVoorkeursmoment (dag, deel) {
      return this.$store.state.vergaarbak.voorkeursmomenten.find((item) => {
        return item.dagDagdeel.dagId === dag && item.dagDagdeel.dagdeelId === deel
      })
    },
    sortAanmeldingen (aanmeldingen) {
      // this.sortColumn
      // find column by label
      const column = this.columns.find((column) => column.label === this.sortColumn.label)
      if (!column) {
        return aanmeldingen
      }

      // sort by column.field
      const sortField = column.field
      if (column.type === 'date') {
        aanmeldingen = aanmeldingen.sort((a, b) => {
          const dateA = new Date(a[sortField])
          const dateB = new Date(b[sortField])
          if (this.sortColumn.direction === 'asc') {
            return dateA - dateB
          } else if (this.sortColumn.direction === 'desc') {
            return dateB - dateA
          } else {
            return 0
          }
        })
      } else {
        aanmeldingen = aanmeldingen.sort((a, b) => {
          console.log(a[sortField])
          if (this.sortColumn.direction === 'asc') {
            return a[sortField] > b[sortField] ? 1 : -1
          } else if (this.sortColumn.direction === 'desc') {
            return a[sortField] < b[sortField] ? 1 : -1
          } else {
            return 0
          }
        })
      }
      return aanmeldingen
    },
    filterDataByVoorkeuren (aanmeldingen) {
      this.columns.forEach((column) => {
        if (column.allowFilter && column.type === 'voorkeuren') {
          const allowedPreftimes = []
          column.filterVoorkeuren.forEach((voorkeur) => {
            allowedPreftimes.push(this.getVoorkeursmoment(voorkeur.dagId, voorkeur.dagdeelId).dagDagdeelId)
          })
          aanmeldingen = aanmeldingen.filter(
            aanmelding => allowedPreftimes.includes(aanmelding.preftime1Id) ||
              allowedPreftimes.includes(aanmelding.preftime2Id) ||
              aanmelding.preftime1Id === 22 || aanmelding.preftime2Id === 22)
        }
      })
      return aanmeldingen
    },
    filterDataBySelect (aanmeldingen) {
      var result = []
      this.columns.forEach((column) => {
        if (column.allowFilter && column.type === 'select') {
          var add = aanmeldingen.filter((aanmelding) => {
            // if aanmelding.field is null of '', allow it
            if (!aanmelding[column.field] && this.toonUnsetNiveaus) {
              return true
            }
            // if aanmelding.field is in filter, allow it
            return column.filter.some(filterItem => filterItem[column.columnId] === aanmelding[column.field])
          })
          result = result.concat(add)
          aanmeldingen = aanmeldingen.filter((aanmelding) => {
            return !add.includes(aanmelding)
          })
        }
      })
      return result
    },
    filterDataByText (aanmeldingen, $and) {
      const keys = this.columns.map((column) => column.filterField || column.field)
      const options = {
        keys,
        threshold: 0.3,
        useExtendedSearch: true
      }
      const fuse = new Fuse(aanmeldingen, options)
      const result = fuse.search({
        $and
      })
      return result.map((item) => item.item)
    },
    filterDataByNumbers (aanmeldingen) {
      this.columns.forEach((column) => {
        if (column.allowFilter && column.type === 'number') {
          if (column.filter.min === '') {
            column.filter.min = column.filterDefault ? column.filterDefault.min : 0
          }
          if (column.filter.max === '') {
            column.filter.max = column.filterDefault ? column.filterDefault.max : 100
          }
          aanmeldingen = this.aanmeldingen.filter((item) => {
            const value = item[column.filterField]
            return value >= column.filter.min && value <= column.filter.max
          })
        }
      })
      return aanmeldingen
    },
    groupClientsFromSameGroup (filteredAanmeldingen) {
      var aanmeldingenCopy = this.aanmeldingen
      var result = []
      filteredAanmeldingen.forEach((aanmelding) => {
        var group = aanmelding.group
        var groupAanmeldingen = aanmeldingenCopy.filter((aanmelding) => {
          return aanmelding.group.groupId === group.groupId && aanmelding.id
        })
        aanmeldingenCopy = aanmeldingenCopy.filter((aanmelding) => {
          return aanmelding.group.groupId !== group.groupId
        })
        result = result.concat(groupAanmeldingen)
      })
      return result
    },
    textFilter: function (data, filterString) {
      return data.toLowerCase().includes(filterString.toLowerCase())
    },
    saveColumns () {
      localStorage.setItem(this.storageKey, JSON.stringify(this.columns))
    },
    searchModalListener (event) {
      // on click f when nothing is in focus
      if (event.keyCode === 70 && !document.querySelector('input:focus')) {
        event.preventDefault()
        this.toggleFilterModal()
      }
      if (event.keyCode === 83 && !document.querySelector('input:focus')) {
        event.preventDefault()
        this.toggleSortModal()
      }
    },
    handleDocumentClick (event) {
      if (this.showFilterModal && !this.$refs.filterModalCard.contains(event.target) &&
       !this.$refs.filterButton.contains(event.target)) {
        this.showFilterModal = false
      }
      if (this.showSortModal && !this.$refs.sortModalCard.contains(event.target) && !this.$refs.sortButton.contains(event.target)) {
        this.showSortModal = false
      }
    },
    toggleFilterModal () {
      this.showSortModal = false
      this.showFilterModal = !this.showFilterModal
    },
    toggleSortModal () {
      this.showFilterModal = false
      this.showSortModal = !this.showSortModal
    },
    toggleSortDirection () {
      if (this.sortColumn.direction === 'asc') {
        this.sortColumn.direction = 'desc'
      } else if (this.sortColumn.direction === 'desc') {
        this.sortColumn.direction = 'none'
      } else {
        this.sortColumn.direction = 'asc'
      }
    },
    onSearchChange ($e, col) {
      col.filter = $e.target.value
    },
    onAanmeldingClick (aanmelding) {
      this.$router.push({
        name: 'Aanmelding',
        params: {
          id: aanmelding.row.id
        }
      })
    }
  },
  watch: {
    columns: {
      handler (newColumns) {
        this.saveColumns(newColumns)
      },
      deep: true
    }
  },
  created () {
    if (this.storedColumns) {
      this.columns = this.columns.map((col) => {
        const storedCol = this.storedColumns.find((c) => c.field === col.field)
        return Object.assign({}, col, storedCol)
      })
    }
    window.addEventListener('keydown', this.searchModalListener)
    window.addEventListener('click', this.handleDocumentClick)

    this.$store.dispatch('vergaarbak/getNiveaus')
  },
  beforeUnmount () {
    window.removeEventListener('keydown', this.searchModalListener)
    window.removeEventListener('click', this.handleDocumentClick)
  }
}
</script>

<style>
    .vgt-global-search {
        border: none;
        background: var(--background-light);
    }
    .vgt-global-search__input .input__icon .magnifying-glass {
        margin-top: 8px;
        margin-left: 12px
    }
    .vgt-global-search input{
        background: none;
        padding: 12px;
        height: auto;
        border: none;
    }
    .vgt-inner-wrap{
        box-shadow: none;
        background: #fff;
        border-radius: 0;
    }
    .vgt-wrap__footer{
        border: none;
        background: #fff;
    }
    .vgt-table.bordered th {
        border: none;
    }
    .vgt-table.bordered tr:hover{
        background: var(--background-light);
        cursor: pointer;
    }
    .vgt-table.bordered td:first-child{
        border-left: none;
    }
    .vgt-table.bordered td:last-child{
        border-right: none;
    }
    table.vgt-table{
        border: none;
    }
    .vgt-table thead th {
        background: var(--background-super-light);
    }
    #opties-row{
      padding: 1em;
      border-bottom: 1px solid #e0e0e0;
    }

    .settings-group{
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      padding: 1.2rem;
      position: absolute;
      z-index: 100;
      background: #fff;
      right: 10px;
    }

    .column-selector {
      display: flex;
      flex-wrap: wrap;
    }

    .column-selector > div{
      width: 100%;
    }

    .column-selector__chip {
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 5px;
      background-color: #fff;
      cursor: move;
      width: 100%;
      gap: 10px;
    }

    .column-selector__label {
      margin-left: 5px;
      cursor: pointer;
      display: flex;
      margin-bottom: 4px;
      align-items: center;
      width: 100%;
    }

    .checkbox-custom {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox-custom__indicator {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      background: rgba(0,0,0,0.1);
      border-radius: 3px;
    }

    .checkbox-custom:checked + .checkbox-custom__indicator{
      background: var(--skipiste-color);
    }

    .checkbox-custom:checked + .checkbox-custom__indicator:after {
      content: '';
      position: absolute;
      left: 7px;
      top: 3px;
      width: 6px;
      height: 12px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }

    .modal{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modal-card{
      background: #fff;
      border-radius: 10px;
      padding: 1.2rem;
      position: relative;
    }

    #options-left{
      display: flex;
      flex-direction: row;
      gap: 1em;
    }

    #filter-button{
      display: flex;
      gap: 4px;
      align-items: center;
    }

</style>
